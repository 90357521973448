<template>
  <div class="appointDetail">
    <div class="box">
      <div class="areaAppointItem">
        <div class="appointList-t">
          <div class="id">工单号：{{ detailData.id }}</div>
          <div class="state">{{ getStatus(detailData) }}</div>
        </div>
        <div class="appointList-c">
          <div class="name">{{ detailData.placeName }}</div>
          <div class="address">{{ detailData.address }}</div>
          <div class="status">
            {{ detailData.scheduleList[0].strTimeStatus }}
          </div>
        </div>
        <div class="appointList-b">
          <div class="timeBox">
            <div class="title">下单时间</div>
            <div class="time">{{ detailData.referTime }}</div>
          </div>
          <div class="timeBox">
            <div class="title">预约场次</div>
            <div class="time">
              <div
                class="scheduleList"
                v-for="(oItem, oIndex) in detailData.scheduleList"
                :key="oIndex"
              >
                {{ oItem.strDateTime }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="opera" v-if="detailData.cancelBook">
      <div class="btn" @click="cancel">取消订单</div>
    </div>
    <div class="emptyBox"></div>
    <v-dialog v-model="isDialog" title="提示" @confirm="confirm">
      <p style="text-align: center; line-height: 40px">是否确认取消订单？</p>
    </v-dialog>
  </div>
</template>

<script>
import { myAppointDetailUrl, cancelAppointUrl } from "./api.js";
import { appointStatus } from "./map.js";
import { getOption } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "appointDetail",
  data() {
    return {
      timer: null,
      orderId: "",
      detailData: {},
      isDialog: false,
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  created() {
    this.orderId = this.$route.query.orderId;
  },
  mounted() {
    this.getMyAppointDetail();
  },
  methods: {
    cancel() {
      this.isDialog = true;
    },
    confirm() {
      let params = {
        orderId: this.orderId,
      };
      this.$axios
        .post(`${cancelAppointUrl}?orderId=${this.orderId}`)
        .then((res) => {
          if (res.code === 200) {
            this.$toast({
              message: "取消成功",
              duration: 300,
            });
            this.timer = setTimeout(() => {
              this.$router.replace({
                name: "appointList",
              });
            }, 400);
          } else {
          }
        });
    },
    getStatus(item) {
      if (item.status) {
        console.log(
          88,
          item,
          getOption(item.status, appointStatus, "key").value
        );
        return getOption(item.status, appointStatus, "key").value;
      } else {
        return "";
      }
    },

    getMyAppointDetail() {
      let params = {
        orderId: this.orderId,
      };
      this.$axios
        .get(`${myAppointDetailUrl}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.detailData = res.data;
          } else {
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.appointDetail {
  box-sizing: border-box;
  min-height: 100vh;
  background: #f4f5ff;
  padding-bottom: 120px;
  padding-top: 28px;

  .box {
    width: 100%;
    min-height: 402px;
    box-sizing: border-box;
    padding: 0 28px;

    .areaAppointItem {
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
      border-radius: 16px;
      height: 100%;
    }

    .appointList-t {
      box-sizing: border-box;
      height: 72px;
      width: 100%;
      position: relative;
      color: rgba(0, 0, 0, 0.25);
      font-size: 24px;

      .id {
        position: absolute;
        left: 38px;
        top: 50%;
        transform: translateY(-50%);
      }

      .state {
        position: absolute;
        right: 32px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .appointList-c {
      box-sizing: border-box;
      width: 100%;
      height: 150px;
      border-top: 2px solid rgba(0, 0, 0, 0.05);
      border-bottom: 2px solid rgba(0, 0, 0, 0.05);
      position: relative;

      .name {
        font-size: 36px;
        font-weight: 600;
        color: #333333;
        position: absolute;
        left: 40px;
        top: 34px;
      }

      .address {
        font-size: 26px;
        color: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 40px;
        top: 82px;
      }

      .status {
        font-size: 30px;
        font-weight: 600;
        color: #5e5aff;
        position: absolute;
        top: 36px;
        right: 32px;
      }
    }

    .appointList-b {
      width: 100%;
      padding: 42px 32px 24px 40px;
      box-sizing: border-box;

      .timeBox {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .title {
          width: 200px;
          font-size: 32px;
          color: rgba(0, 0, 0, 0.5);
        }

        .time {
          flex: 1;
          font-size: 30px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          text-align: right;
        }
      }
    }
  }

  .opera {
    width: 100%;
    padding: 0 100px;
    left: 0;
    bottom: 134px;
    position: fixed;
    height: 66px;
    box-sizing: border-box;

    .btn {
      box-sizing: border-box;
      height: 66px;
      border-radius: 10px;
      border: 2px solid;
      font-size: 30px;
      font-weight: 600;
      color: #605cff;
      text-align: center;
      line-height: 66px;
    }
  }
  .emptyBox {
    width: 100%;
    height: 76px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
  }
}
</style>
